<template>
  <div class="bank">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">供应链金融</div>
    <!-- <van-popup v-model:show="show" closeable round>
      <div class="pop_box">
          <img src="../../assets/img/tanbg.jpg" alt="">
          <div class="pop_text">
            请登录桂信融网站<br>http://183.62.50.114:18999/gxfinan_org/<br>填写资料和上传附件办理！
          </div>
          <div class="pop_btn" @click="closePopup">
            确定
          </div>
      </div>
    </van-popup> -->
    <div class="jl_box" @click="showPopup(1)">
      <img src="../../assets/img/jl1.png" alt="">
      <div class="jl_text">
        <p>应收账款融资<br>模式奖励</p>
      </div>
    </div>
    <div class="jl_box" @click="showPopup(2)">
      <img src="../../assets/img/jl2.png" alt="">
      <div class="jl_text">
        <p>跨境金融区块链<br>服务平台接入机构奖励</p>
      </div>
    </div>
    <div class="jl_box" @click="showPopup(3)">
      <img src="../../assets/img/jl3.png" alt="">
      <div class="jl_text">
        <p>供应链金融<br>第三服务机构奖励</p>
      </div>
    </div>
    <div class="jl_box" @click="showPopup(4)">
      <img src="../../assets/img/jl4.png" alt="">
      <div class="jl_text">
        <p>供应链金融票据<br>签发奖励</p>
      </div>
    </div>
    <div class="jl_box" @click="showPopup(5)">
      <img src="../../assets/img/jl5.png" alt="">
      <div class="jl_text">
        <p>供应链信息<br>平台奖励</p>
      </div>
    </div>
    <div class="jl_box" @click="showPopup(6)">
      <img src="../../assets/img/jl6.png" alt="">
      <div class="jl_text">
        <p>金融机构服务<br>供应链金融奖励</p>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router'
export default {
   setup() {
     let router = useRouter();

     const showPopup = (id) => {
      // show.value = true;
      router.push({
        path: '/supplyChainApply',
        query: {
          rId: id
        }
      })
    };

    return { 

      showPopup,

     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.bank {
  padding-top: 16.5vw;
  .jl_box {
    display: flex;
    background-color: #fff;
    align-items: center;
    margin: 0 4vw 4vw;
    padding: 2vw 4vw;
    border-radius: 2vw;
    box-shadow: 0 0 3vw rgba(0,0,0,0.1);
    img {
      width: 20vw;
    }
    .jl_text {
      margin-left: 2vw;
      padding-left: 4vw;
      border-left: 1px solid #ccc;
      p {
        font-size: 4vw;
        font-weight: 500;
        line-height: 7vw;
      }
      
    }
  }
  .pop_box {
    width: 90vw;
    padding-bottom: 4vw;
    background-color: #fff;
    img {
      width: 100%;
    }
    .pop_text {
      margin-top: -5vw;
      text-align: center;
      font-size: 4vw;
      color: #fb8800;
      font-weight: 600;
      line-height: 7vw;
    }
    .pop_btn {
      margin: 4vw auto;
      width: 70%;
      font-size: 4.5vw;
      border-radius: 2vw;
      color: #fff;
      // background-color: #00a1fe;
      background: linear-gradient(to right, #ff9900, #ff6700);
      text-align: center;
      padding: 2vw 0;
    }
  }
}
</style>
